// ES6
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';

const MapBox = ReactMapboxGl({
  accessToken:
    'pk.eyJ1Ijoid2FzaHRyYXgiLCJhIjoiY2tsMGlrZWtoMG04dDJ2cGU4cm9iMjdsaCJ9.JilTU4CQmQ-mDimrx10vnw',
});

const Map = (props) => {
  return (
    <div>
      <MapBox
        style="mapbox://styles/mapbox/streets-v9"
        containerStyle={{
          height: '100vh',
          width: '100vw',
        }}
      >
        <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
          <Feature coordinates={[-0.481747846041145, 51.3233379650232]} />
        </Layer>
      </MapBox>
    </div>
  );
};

export default Map;
// in render()
